// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-dnb-js": () => import("./../../../src/pages/portfolio/dnb.js" /* webpackChunkName: "component---src-pages-portfolio-dnb-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-paypal-js": () => import("./../../../src/pages/portfolio/paypal.js" /* webpackChunkName: "component---src-pages-portfolio-paypal-js" */),
  "component---src-pages-portfolio-phunware-js": () => import("./../../../src/pages/portfolio/phunware.js" /* webpackChunkName: "component---src-pages-portfolio-phunware-js" */),
  "component---src-pages-portfolio-resideo-js": () => import("./../../../src/pages/portfolio/resideo.js" /* webpackChunkName: "component---src-pages-portfolio-resideo-js" */),
  "component---src-pages-portfolio-unity-js": () => import("./../../../src/pages/portfolio/unity.js" /* webpackChunkName: "component---src-pages-portfolio-unity-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

